import React from 'react';
import "./src/theme/custom.scss";

export const wrapRootElement = ({element}) => {
    return (<>{element}</>);
}

export const onInitialClientRender =() =>{
    const s = document.createElement(`script`);
    s.type = `text/javascript`;
    s.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.getElementsByTagName(`head`)[0].appendChild(s);
}