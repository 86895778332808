// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-tour-guide-js": () => import("./../../src/pages/tour-guide.js" /* webpackChunkName: "component---src-pages-tour-guide-js" */),
  "component---src-pages-tours-js": () => import("./../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-track-js": () => import("./../../src/pages/track.js" /* webpackChunkName: "component---src-pages-track-js" */),
  "component---src-templates-single-tour-js": () => import("./../../src/templates/SingleTour.js" /* webpackChunkName: "component---src-templates-single-tour-js" */)
}

